
import { ModelObject } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DomainModule } from '@/modules/domain/store'
import { ModelModule } from '@/modules/model/store'

import ListItem, { IModelObjectSelectMenuListItem } from './list-item.vue'

const MAX_LIST_ITEMS = 12

@Component({
  components: {
    ListItem
  },
  name: 'ModeObjectSelectMenuList'
})
export default class extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)

  search = ''
  searchModel = ''

  expandedIds: string[] = []
  searchExpandedIds: string[] = []

  listHeight = MAX_LIST_ITEMS * 32

  @Prop() readonly activeObject?: ModelObject
  @Prop() readonly objectIcons!: {}
  @Prop() readonly objects!: ModelObject[]
  @Prop({ default: 'Search objects' }) readonly placeholder!: string
  @Prop({ default: 'No objects' }) readonly emptyStateText!: string

  @Ref() readonly virtualScrollRef?: { $el: HTMLElement }
  @Ref() readonly searchRef?: HTMLElement

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get currentRootModelObject () {
    return Object.values(this.modelModule.objects).find(o => o.type === 'root' && o.domainId === this.currentDomain?.id)
  }

  get rootModelObjectIds () {
    return Object.values(this.modelModule.objects).filter(o => o.type === 'root').map(o => o.id)
  }

  get sortedObjects () {
    return this.objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  get searchedObjectIds () {
    if (this.search) {
      const fuse = new Fuse(this.objects, {
        keys: [
          'caption',
          'name',
          'status',
          'type'
        ],
        threshold: 0.3
      })
      return fuse
        .search(this.search)
        .map(o => [o.item.id, ...o.item.parentIds])
        .flat()
    } else {
      return []
    }
  }

  get expandedObjects () {
    const objects: { object: IModelObjectSelectMenuListItem, model: ModelObject }[] = []

    const searchedObjectIds = this.searchedObjectIds

    this.objects
      .filter(l1 => (
        (!this.search || searchedObjectIds.includes(l1.id)) &&
        (!l1.parentId || this.currentRootModelObject ? l1.parentId === this.currentRootModelObject?.id : this.rootModelObjectIds.includes(l1.parentId)))
      )
      .forEach(l1 => {
        const expanded = this.search ? this.searchExpandedIds.includes(l1.id) : this.expandedIds.includes(l1.id)

        const l2Items = this.objects.filter(l2 => l2.parentId === l1.id && (!this.search || searchedObjectIds.includes(l2.id)))
        const l2ItemsExpanded = expanded ? l2Items : []

        objects.push({
          model: l1,
          object: {
            active: this.activeObject ? this.activeObject.id === l1.id : undefined,
            expandable: !!l2Items.length,
            expanded,
            indent1: false,
            indent2: false
          }
        })

        l2ItemsExpanded.forEach((l2, l2Index, l2Arr) => {
          const expanded = this.search ? this.searchExpandedIds.includes(l2.id) : this.expandedIds.includes(l2.id)

          const l3Items = this.objects.filter(l3 => l3.parentId === l2.id && (!this.search || searchedObjectIds.includes(l3.id)))
          const l3ItemsExpanded = expanded ? l3Items : []

          const l2First = l2Index === 0
          const l2Last = l2Index === l2Arr.length - 1
          const l2FirstAndLast = l2First && l2Last && l2Arr.length === 1

          objects.push({
            model: l2,
            object: {
              active: this.activeObject ? this.activeObject.id === l2.id : undefined,
              expandable: !!l3Items.length,
              expanded,
              indent1: l2FirstAndLast && l3ItemsExpanded.length === 0 ? 'first-and-last' : l2First ? 'first' : l2Last && l3ItemsExpanded.length === 0 ? 'last' : true,
              indent2: false
            }
          })

          l3ItemsExpanded.forEach((l3, l3Index, l3Arr) => {
            const l3First = l3Index === 0
            const l3Last = l3Index === l3Arr.length - 1
            const l3FirstAndLast = l3First && l3Last && l3Arr.length === 1

            objects.push({
              model: l3,
              object: {
                active: this.activeObject ? this.activeObject.id === l3.id : undefined,
                expandable: false,
                expanded: this.search ? this.searchExpandedIds.includes(l3.id) : this.expandedIds.includes(l3.id),
                indent1: l2Last && l3Last ? 'last' : true,
                indent2: l3FirstAndLast ? 'first-and-last' : l3First ? 'first' : l3Last ? 'last' : true
              }
            })
          })
        })
      })

    return objects
  }

  @Watch('expandedObjects')
  onExpandedObjectsChanged (expandedObjects: IModelObjectSelectMenuListItem[]) {
    this.listHeight = Math.min(MAX_LIST_ITEMS * 32, expandedObjects.length * 32)
  }

  @Watch('searchedObjectIds')
  onSearchedObjectIdsChanged (objectIds: string[], prevObjectIds: string[]) {
    const newObjectIds = objectIds.filter(o => !prevObjectIds.includes(o) && !this.searchExpandedIds.includes(o))
    this.searchExpandedIds = [...new Set([...this.searchExpandedIds, ...newObjectIds])]
  }

  @Watch('search')
  onSearchChanged () {
    if (this.virtualScrollRef) {
      this.virtualScrollRef.$el.scrollTop = 0
    }
  }

  mounted () {
    this.listHeight = Math.min(MAX_LIST_ITEMS * 32, this.expandedObjects.length * 32)
  }

  setSearchDebounce = debounce(this.setSearch.bind(this), 300)

  setSearch (term: string) {
    this.search = term
  }

  toggleExpanded (id: string) {
    const ids = this.search ? this.searchExpandedIds : this.expandedIds
    const index = ids.indexOf(id)
    if (index >= 0) {
      ids.splice(index, 1)
    } else {
      ids.push(id)
    }
  }
}
