
import { ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import * as icons from '@/modules/code/helpers/icons'

@Component({
  name: 'ModelObjectLinksListItem'
})
export default class extends Vue {
  @Prop() readonly object!: ModelObject
  @Prop() readonly linkId!: string

  fileIcons = icons.files
  linkIcons = icons.links

  get name () {
    if (this.link.customName) {
      return this.link.customName
    } else if (this.link.type === 'url') {
      return this.link.name
    } else {
      return this.link.name.split('/').pop()
    }
  }

  get extension () {
    if ('path' in this.link) {
      return this.link.path.split('.').pop()
    }
  }

  get link () {
    return this.object.links[this.linkId]
  }

  get linkInvalid () {
    return 'status' in this.link && this.link.status === 'invalid'
  }

  get linkValid () {
    return 'status' in this.link && this.link.status === 'valid'
  }
}
