
import { ModelObject, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'

import * as analytics from '../../helpers/analytics'
import { ModelModule } from '../../store'

interface Item {
  active: boolean
  click: () => void
  icon: string
  id: string
  subtitle: string
  title: string
}

@Component({
  name: 'ModelObjectExternal'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)

  @Prop() readonly object!: ModelObject
  @Prop() readonly permission!: PermissionType

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get external () {
    return this.object.external || this.modelModule.objects[this.object.id]?.parentIds.map(o => this.modelModule.objects[o]).some(o => o.external)
  }

  get items (): Item[] {
    return [
      {
        active: !this.external,
        click: () => this.updateExternal(false),
        icon: '$fas-building',
        id: 'internal',
        subtitle: 'Within the scope of your business',
        title: 'Internal'
      },
      {
        active: this.external,
        click: () => this.updateExternal(true),
        icon: '$fas-globe',
        id: 'external',
        subtitle: 'Outside the scope of your business',
        title: 'External'
      }
    ]
  }

  get currentItem () {
    return this.items.find(o => o.active)
  }

  get editableType () {
    return this.object.type === 'system' || this.object.type === 'actor'
  }

  get editable () {
    return this.permission !== 'read'
  }

  updateExternal (external: boolean) {
    if (this.object.external === external) {
      return
    }

    const prevObject = { ...this.object }

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    if (this.currentDiagram?.status === 'draft') {
      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: [{
              id: this.object.id,
              props: {
                external: this.object.external
              },
              type: 'model-object-update'
            }]
          }
        },
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: [{
            id: this.object.id,
            props: {
              external
            },
            type: 'model-object-update'
          }]
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: diagramContent.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.object.versionId
        })
      })

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      revertTasks.push({
        id: this.object.id,
        props: {
          external: this.object.external
        },
        type: 'model-object-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { object, objectUpdate } = this.modelModule.generateObjectCommit(this.object.id, {
        external
      })
      this.modelModule.setObjectVersion(object)
      this.editorModule.addToTaskQueue({
        func: () => this.modelModule.objectUpdate({
          landscapeId: this.currentLandscape.id,
          objectId: object.id,
          props: objectUpdate,
          versionId: this.object.versionId
        })
      })

      tasks.push({
        id: object.id,
        props: objectUpdate,
        type: 'model-object-update'
      })

      analytics.modelObjectUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectDescriptionLength: prevObject.description?.length || 0,
        modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
        modelObjectExternal: prevObject.external,
        modelObjectIconName: prevObject.icon?.name || null,
        modelObjectLinkCount: Object.keys(prevObject.links).length,
        modelObjectNameLength: prevObject.name.length,
        modelObjectParent: prevObject.parentId,
        modelObjectStatus: prevObject.status,
        modelObjectTagCount: prevObject.tagIds.length,
        modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
        modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
        modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
        modelObjectType: prevObject.type,
        modelObjectUpdateExternal: objectUpdate.external,
        organizationId: [this.currentLandscape.organizationId]
      })
    }

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [
        ...tasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })
  }
}
