
import { ModelObject, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  name: 'ModelObjectCaption'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly field!: any

  @Prop() readonly object!: ModelObject
  @Prop() readonly permission!: PermissionType

  model = ''
  editing = false
  focused = false
  maxLength = 120

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  @Watch('model')
  onModelChanged (newVal?: string) {
    this.model = newVal?.slice(0, this.maxLength) || ''
  }

  @Watch('object.caption')
  onObjectCaptionChanged (newVal?: string) {
    this.model = newVal || ''
  }

  mounted () {
    this.model = this.object.caption || ''

    this.$nextTick(() => {
      this.field?.$refs.input?.setAttribute('maxlength', this.maxLength)
    })
  }

  enterKey (event: KeyboardEvent) {
    if (!event.shiftKey) {
      event.preventDefault()
      this.field.blur()
    }
  }

  escapeKey () {
    this.model = this.object.caption || ''
    this.field.blur()
  }

  updateName (caption: string) {
    const slicedCaption = caption.slice(0, this.maxLength)
    if (this.object.caption !== slicedCaption) {
      const tasks: Task[] = []
      const revertTasks: Task[] = []

      if (this.currentDiagram?.status === 'draft') {
        revertTasks.push({
          id: this.currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: this.object.id,
                props: {
                  caption: this.object.caption
                },
                type: 'model-object-update'
              }]
            }
          },
          type: 'diagram-content-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })

        const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
          tasksProposed: {
            $append: [{
              id: this.object.id,
              props: {
                caption: slicedCaption
              },
              type: 'model-object-update'
            }]
          }
        })
        this.diagramModule.setDiagramContentVersion(diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscapeId,
            props: diagramContentUpdate,
            versionId: this.currentVersionId
          })
        })

        tasks.push({
          id: diagramContent.id,
          props: diagramContentUpdate,
          type: 'diagram-content-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })
      } else {
        revertTasks.push({
          id: this.object.id,
          props: {
            caption: this.object.caption
          },
          type: 'model-object-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })

        const { object, objectUpdate } = this.modelModule.generateObjectCommit(this.object.id, { caption: slicedCaption })
        this.modelModule.setObjectVersion(object)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscapeId,
            objectId: object.id,
            props: objectUpdate,
            versionId: this.currentVersionId
          })
        })

        tasks.push({
          id: object.id,
          props: objectUpdate,
          type: 'model-object-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })
      }

      this.editorModule.addTaskList({
        revertTasks,
        tasks
      })
    }
  }
}
