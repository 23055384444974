
import { ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { iconUrlForTheme } from '@/helpers/theme'

export interface IModelObjectSelectMenuListItem {
  active?: boolean
  expandable: boolean
  expanded: boolean
  indent1: boolean | 'first' | 'last' | 'first-and-last'
  indent2: boolean | 'first' | 'last' | 'first-and-last'
}

@Component({
  name: 'ModelObjectSelectMenuListItem'
})
export default class extends Vue {
  iconUrlForTheme = iconUrlForTheme

  @Prop() readonly object!: IModelObjectSelectMenuListItem
  @Prop() readonly model!: ModelObject
}
