
import { ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { DomainModule } from '@/modules/domain/store'

import { ModelModule } from '../../store'
import ObjectFamilyMenu from './object-family-menu.vue'

@Component({
  components: {
    ObjectFamilyMenu
  },
  name: 'ModelObjectParent'
})
export default class extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)

  @Prop() readonly model!: ModelObject | ModelObject[]
  @Prop() readonly permission!: PermissionType

  get objects () {
    return this.model instanceof Array ? this.model : [this.model]
  }

  get objectHandleIds () {
    return this.objects.map(o => o.handleId)
  }

  get parents () {
    return this.parentIds.map(o => {
      const object = this.modelModule.objects[o]
      const domain = this.domainModule.domains[o]
      if (object) {
        return object
      } else {
        return domain
      }
    })
  }

  get parentNames () {
    return this.parentIds.map(o => {
      const object = this.modelModule.objects[o]
      const domain = this.domainModule.domains[o]
      if (object) {
        return object.name || `${object.type.slice(0, 1).toUpperCase()}${object.type.slice(1)}`
      } else {
        return domain.name
      }
    })
  }

  get parentIds () {
    const parentIds = this.objects.map(o => {
      const parent = o.parentId ? this.modelModule.objects[o.parentId] : null
      if (parent?.type === 'root') {
        return parent.domainId
      } else if (parent) {
        return parent.id
      } else {
        return null
      }
    }).filter((o): o is string => !!o)
    return [...new Set(parentIds)]
  }

  get multiple () {
    return this.parents.length > 1
  }

  get parent () {
    return this.parents.find(o => o) || null
  }

  get parentName () {
    return this.parentNames.find(o => o) || null
  }

  get parentIcon () {
    return this.parent && 'type' in this.parent && this.parent.icon ? iconUrlForTheme(this.parent.icon) : undefined
  }

  get editable () {
    return this.permission !== 'read'
  }
}
