
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { ModelModule } from '@/modules/model/store'

@Component({
  name: 'ModelObjectPreviewList'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)

  @Ref() readonly virtualScrollRef!: any

  @Prop() readonly ids!: string[]

  get items () {
    return this.ids
      .sort((a, b) => a > b ? 1 : -1)
      .map(o => {
        const object = this.modelModule.objects[o]
        const connection = this.modelModule.connections[o]
        if (object) {
          const objectName = object ? object.name || `${object.type.substr(0, 1).toUpperCase()}${object.type.substr(1)}` : undefined
          const objectDiagrams = object ? Object.keys(object.diagrams).length : undefined
          const objectType = `${object.type.slice(0, 1).toUpperCase()}${object.type.slice(1)}`
          return {
            subtitle: `${objectType} in ${objectDiagrams} diagram${objectDiagrams === 1 ? '' : 's'}`,
            title: objectName
          }
        } else if (connection) {
          const connectionName = connection ? connection.name || 'Connection' : undefined
          const connectionDiagrams = connection ? Object.keys(connection.diagrams).length : undefined
          return {
            subtitle: `Connection in ${connectionDiagrams} diagram${connectionDiagrams === 1 ? '' : 's'}`,
            title: connectionName
          }
        } else {
          return {
            subtitle: '',
            title: ''
          }
        }
      })
  }

  @Watch('items')
  onItemsChanged () {
    this.virtualScrollRef?.onScroll()
  }

  resize () {
    this.virtualScrollRef?.onScroll()
  }
}
