
import { ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { objectDescriptions, objectIcons } from '../../helpers/objects'

@Component({
  name: 'ModelObjectType'
})
export default class extends Vue {
  @Prop() readonly object!: ModelObject
  @Prop() readonly permission!: PermissionType

  get editable () {
    return this.permission !== 'read'
  }

  objectDescriptions = objectDescriptions
  objectIcons = objectIcons
}
